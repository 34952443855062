
@font-face {
    font-family: "NunitoBold";
    src: url(../nunitoSans/NunitoSans-Bold.ttf)
    }
    @font-face {
        font-family: "NunitoNormal";
        src: url(../nunitoSans/NunitoSans-Regular.ttf)
        }
.descriptionText{
    /* BURN PM is a nighttime thermogenic engineered to make you burn fat specifically while you sleep. BURN PM’s thermogenic formula includes powerful natural sleep enhancers to help you enjoy deep, Cortiosl-crushing sleep. */
max-width: 550px;
position: relative;
width: 120%;

font-family: "NunitoNormal";
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 23px;


}
.productTitle1{
    position: relative;
font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 26px;
line-height: 26px;
}
.title1{
    position: relative;
font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 22px;
}
.justifyEnd{
   text-align: end;
}
.title2{
position: relative;
font-family: Helvetica Neue;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 18px;
}
.title0{
font-family: "NunitoBold";
font-style: normal;
font-weight: 900;
font-size: 42px;
line-height: 57px;
color: #FFFFFF;
}
.mainTitle1{
    font-family: "NunitoBold";
    font-style: normal;
    font-weight: 900;
    font-size: 160px;
    line-height: 160px;
    color: #FFFFFF;
font-weight: bold;
}
.mainTitle2{
    font-family: "NunitoBold";
    font-weight: 900;
    line-height: 110px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 300;

font-size: 110px;
color: rgba(255, 255, 255, 0.74);

}
.buttonGreyText{
    /* SHOP */

position: relative;
font-family: NunitoBold;
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 38px;

color: #000000;


}
.navHead1{
position: relative;
font-family: Helvetica Neue;
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;

}
.grey2{
    color: #848484;
}
.title3{
    position: relative;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    
    }
.white{
    color:#ffffff;
}
.green1{
    color:#398047;
}
.bold{
    font-weight: bold !important;
}
.caption3{
    position: static;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    }
    .normalText::placeholder{
        z-index: 3;
        height: 19px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      
      letter-spacing: 0.02em;
      
      color: #000000;
      }
      .invisible::placeholder{
            z-index: 3;
            height: 19px;
          font-family: 'Helvetica Neue';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height */
          
          letter-spacing: 0.02em;
          
          color: #000000;
      }
      .normalText{
        z-index: 3;
        height: 19px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */
      
      letter-spacing: 0.02em;
      
      color: #000000;
      
      }
    .body1{
        position:static;
        width: auto;
font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 16px;
    }

    .body2{
        position:static;
        width: auto;
font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 14px;
    }
    .body3{
        position:static;
        width: auto;
font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 12px;
    }