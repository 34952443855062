.html{
    /* border:0px !important;
    margin: 0px !important;
    padding:0px !important; */
}
.checkoutLine{
    display:grid;
grid-template-columns: 8fr 1fr;
grid-template-rows: 100%;
}
.editAddressCard{
position: relative;
width: 165px;
background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 20px;
padding:15px;
margin:6px;
}
.outerSelector{
    padding:0px;
    border: 4px solid green;
    border-radius: 20px;
}
.paymentMethodsCard{
    margin:15px;
position:relative;
width: 80%;
height: 130px;
background: rgba(255, 255, 255, 0.8);
border: 0.1px solid #000000;
box-sizing: border-box;
border-radius: 10px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.creditCardCard{
    margin:15px;
position:relative;
width: 150px;
height: 110px;
background: rgba(255, 255, 255, 0.8);
border: 0.1px solid #000000;
box-sizing: border-box;
border-radius: 10px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.paymentMiniCardBox{
    display:grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 7px;
    max-width: 200px;
}
.paymentMiniCard{
    /* Rectangle 48 */

position: relative;
width: 41px;
height: 26px;
background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 4px;

}
.image-hover-highlight {
    -webkit-transition: all 0.50s;
    transition: all 0.50s;
}
    .image-hover-highlight:hover {
        border: 2px solid green;
        filter: brightness(130%);
        -webkit-filter: brightness(130%);
        -moz-filter: brightness(130%);
        -o-filter: brightness(130%);
        -ms-filter: brightness(130%);
        -webkit-transition: all 0.50s;
        transition: all 0.50s;
    }
    .fieldBox{
        position:relative;
        display:flex;
        padding-left: 1rem;
        align-items: center;
        width: 100%;
      height: 57px;
      
      border: 0.1rem solid #949494;
      box-sizing: border-box;
      border-radius: 8px;
      transform: rotate(0);
      }
      .glowing-border {
          padding:1rem;
        border: 0.1px solid rgb(163, 163, 163);
        border-radius: 7px;
        transition: all 0.1s linear;
    }
    .roundedBorder{
        background: rgba(255, 255, 255, 0.8);
        border: 0.2px solid rgb(230, 230, 230);
        box-sizing: border-box;
        border-radius: 4px;

    }
    .hrElement{
        width: 97%;
        border-bottom:1px solid rgb(230, 230, 230);
    }
    .hrFullElement{
        width: 100%;
        border-bottom:1px solid rgb(230, 230, 230);
    }
    .glowing-border:focus { 
        outline: none;
        border: 2px solid black;
        border-color: black;
        box-shadow: 0 0 2px black;
    }
      .bottomFieldBox{
        border-radius: 0px 0px 8px 8px !important;
      }
      .upperFieldBox{
        border-bottom: 0px !important;
        border-radius: 8px 8px 0px 0px !important;
      }
      .wideButton{
        z-index: 1;
        display: flex;
        width: 100%;
        height: 40px;
        background: #37A000;
        border-radius: 5px;
      }
      .centerCard2{
        margin-top:28px;
        display: grid;
        grid-template-columns: 1fr;
        padding:20px;
      }
      .optionBox{
        position:relative;
        z-index: 2;
        width: 100%;
        height:46px;
        background: #FFFFFF;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 5px;
      }
.selectedMini{
    border: 2px solid green;
}
.navBarItems{
    display:grid;
    grid-column-gap: 15px;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
}
.orBox{
    display: grid;
    grid-template-columns: 8fr 1fr 8fr;
}
.checkoutCard{
    /* Rectangle 11 */

position: relative;
width: 100%;
height: auto;

background: #FFFFFF;
border: 0.2px solid #000000;
box-sizing: border-box;
border-radius: 20px;
display:grid;
grid-template-columns: 4fr 7fr;
grid-template-rows: 100%;
}
.checkoutPicture{
    /* IMG_0391 1 */

width: 110px;
height: 100px;
background-size: 100% 100%;
background-image: url(../images/burn.webp);
background-image: --webkit-image-set(url(../images/burn.png) 1x);
background-image: image-set(url(../images/burn.png) 1x);


}
.landingBackground{
    padding-top: 50px;
    width: 100vw;
    height: 100vh;
    background-image: url(../images/sexy.webp);
    background-image: --webkit-image-set(url(../images/sexy.png) 1x);
    background-image: image-set(url(../images/sexy.png) 1x);
    background-size: 100% 100%;
    background-position: 70% 0%;
    background-repeat: no-repeat;
}
.cartHover{
    display: none;
}
.button1{
    position: relative;
    height: 42px;
    width:80%;
    padding:10px;
    background: #3665F1;
    border-radius: 30px;
}
.buttonHalf{
    position: relative;
    height: 42px;
    width:45%;
    padding:10px;
    background: #3665F1;
    border-radius: 30px;
    }
.button:hover{
    cursor: pointer;
    text-decoration: none;
}
.buttonGrey{
    /* Rectangle 6 */

    position: relative;
    width: 180px;
    height: 69px;

    background: #FFFFFF;
    border-radius: 10px;

    }
.buttonRed{
    position: relative;
    width: auto;
    height: auto;
    background: #EA1006;
    border-radius: 4px;
    padding:10px;
}
.buttonBlack{
    width: 100% !important;
    position: relative;
    height: 55px;
    background: black;
    border-radius: 4px;
}
.link:hover{
    cursor: pointer;
    text-decoration: none;
}
#cartIcon:hover .cartHover{
    background-color: white;
    z-index: 900 !important;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 190px;
    right:0rem;
    min-height: 160px;
    display: flex;
    position: absolute;
    top:50px;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
}
.flexEnd{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flexStart{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.redCircle{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px !important;
    height: 26px !important;
    top: 6px;
    right: 5px;
    background: #D3384C;
    border: 4px solid #FFFFFF;
    border-radius: 14px;
}
.productCard{
    position: relative;
    width: 347px;
    height: 515px;
    background: #FFFFFF;
    border: 0.1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding:20px;
}
.singleProductCard{
    
    padding-left:40px !important;
    padding-right:40px !important;
    padding-bottom: 40px !important;
    position: relative;
    width: 80%;
    height: auto;
    background: #FFFFFF;
    border: 0px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding:20px;
    }
.socialTriade{
    grid-column-gap: 10px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    }
.productImage{
    position: relative;
    width: 100%;
    height: 65%;
    background-size: 100% 100%;
    background-image: url(../images/burn.webp);
    background-image: --webkit-image-set(url(../images/burn.png) 1x);
    background-image: image-set(url(../images/burn.png) 1x);
    
}
.miniCard{
    position: relative;
    width: 70px;
    height: 60px;
    background-size: 100% 100%;
    background-image: url(../images/burn.webp);
    background-image: --webkit-image-set(url(../images/burn.png) 1x);
    background-image: image-set(url(../images/burn.png) 1x);
    
    opacity: 60% !important;
}

.selected{
    opacity: 100% !important;
}
.miniCard:hover{
    border: 4px solid red;
    border-radius: 10%;
    opacity: 100% !important;
}
.singleProductImage{
    position: relative;
    min-width: 400px;
    height: 370px;
    background-size: 100% 100%;
    background-image: url(../images/burn.webp);
    background-image: --webkit-image-set(url(../images/burn.png) 1x);
    background-image: image-set(url(../images/burn.png) 1x);
    

}
.flex{
    display: flex;
    width: 100%;
    align-items: center;
}
.detailTable{
    position: relative;
    width: 100%;
    height:120px;
    display:grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows: 30px 30px 30px 30px;
}
.paymentLineShort{
    position: relative;
    width: 100%;
    height:30px;
    display:grid;
    grid-template-columns: 8fr 2fr;
    grid-template-rows: 50px;
}
.paymentLineLarge{
    position: relative;
    width: 100%;
    height:30px;
    display:flex;
    justify-content: flex-end;
}
.paymentTable{
    position: relative;
    width: 100%;
    height:90px;
    display:grid;
    grid-template-columns: 5fr 5fr;
    grid-template-rows: 30px 30px 30px;
}
.grid{
    width: 100%;
    height:100%;
    position: relative;
}
.mainNavBar{
    z-index: 1000;
    padding-left: 5rem;
    padding-right: 5rem;
    position: fixed;
    width: 100%;
    height: 44px;
    top:26px;
    left: 0px;
    background: #FFFFFF;
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows:44px;
}
.mobileMainNavBar{
    z-index: 1000;
    padding-left: 5rem;
    padding-right: 5rem;
    position: fixed !important;
    width: 100%;
    height: 44px;
    top: 26px;
    background: #FFFFFF;
    transform: matrix(1, 0, 0, 1, 0, 0);
    /* display: grid;
    grid-template-columns: 4fr 6fr;
    grid-template-rows:44px; */
}
.spaceAround{
    display: flex;
    justify-content: space-around;
}
.navPlain{
    z-index:10;
    position:fixed;
    top:0px;
    background-color: white;
    align-items: center;
    width: 100vw;
    height:60px;
    display:grid;
    grid-template-columns: 1fr 6fr 1fr;
    grid-template-rows: auto;
}
.checkoutBody{
    padding-left:15px;
    padding-right:15px;
    position:relative;
    margin-bottom: 20px;
    display:grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
    
      
}
.leftPanel{
    max-width: 500px;
}
.shippingTotalPanel{
    height:100vh;
    width: 40vw;
    position:fixed;
    display: none;
    right:0vw;
    background-color: #fafafa;
    border-left: 1px solid rgb(212, 212, 212);
}
.profilePicture{
    position: relative;
    width: 82px;
    height: 82px;
    background-size:100% 100%;
    background-repeat: no-repeat;
    background-image: url(../images/profilePic.webp);
    background-image: --webkit-image-set(url(../images/profilePic.jpg) 1x);
    background-image: image-set(url(../images/profilePic.jpg) 1x);
    
    border-radius: 50%;

}
.feedBackBg{
    width: 100%;
    height: 141px;
    background: #EDEDED;
    border-radius: 15px;

}
.checkoutInnerLine{
    display:grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
}
.bigShade{
    /* Rectangle 50 */

    position: absolute;
    width: 50%;
    height: 100%;
    right: 0px;
    top: 0px;

    background: linear-gradient(270.52deg, #000000 0.5%, rgba(0, 0, 0, 0.41) 76.38%, rgba(0, 0, 0, 0) 99.61%);

}
.mainBody{
    position: relative;
    top:60px;
    align-content: center;
    width: 100vw;
    padding-bottom: 40px;
}
.mainBodyStore{
    position: relative;
    top:60px;
    align-content: center;
    width: 100vw;
    padding-bottom: 40px;
}
.orderCard{
    position: relative;
    width: 374px;
    height: 184px;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
}
.addressCard{
    padding:20px;
    position: relative;
    width: 374px;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
}
.itemDetailInfo{
    position:relative;
    display:grid;
    width:100%;
    height:180px;
    max-width: 380px;
    grid-template-columns:100%;
    grid-template-areas:160px;
}
.itemDetailInfoBottom{
    bottom:-40px;
    left:-1px;
    position:absolute;
    display:grid;
    width:100.3%;
    height:40px;
    grid-template-columns:1fr 1fr;
    grid-template-areas:auto;
}
.itemPicture{
    /* IMG_0391 1 */

    position: absolute;
    left:20px;
    top:30px;
    width: 93px;
    height: 89px;
    background-size:100% 100%;
    background-repeat: no-repeat;
    background-image: url(../images/itemsample.jpg);
    background-image: --webkit-image-set(url(../images/itemsample.jpg) 1x);
    background-image: image-set(url(../images/itemsample.jpg) 1x);
    

}
.top1{
    position:relative;
    width:100%;
    height:136px;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 20px 20px 0px 0px;
}

/* Rectangle 12 */
.bottom1{
    
    position:relative;
    width:100%;
    background: #FFFFFF;
    border-bottom: 0.2px solid #000000;
    border-top: 0.2px solid #000000;
    border-left: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 0px 0px 0px 20px;
}

.bottom2{
    position:relative;
    width:100%;
    background: #FFFFFF;
    border: 0.2px solid #000000;
    box-sizing: border-box;
    border-radius: 0px 0px 20px 0px;
}
.t5{
    padding-top:35px;
}
.b5{
    padding-bottom:35px;
}
.l5{
    padding-left:35px;
}
.r5{
    padding-right:35px;
}
.r1{
    padding-right: 5px;
}
.r2{
    padding-right: 10px;
}
.r3{
    padding-right: 15px;
}
.r4{
    padding-right: 20px;
}
.l1{
    padding-left: 5px;
}
.l2{
    padding-left: 10px;
}
.l3{
    padding-left: 15px;
}
.l4{
    padding-left: 20px;
}
.t1{
    padding-top: 5px;
}
.t2{
    padding-top: 10px;
}
.t3{
    padding-top: 15px;
}
.t4{
    padding-top: 20px;
}
.b1{
    padding-bottom: 5px;
}
.b2{
    padding-bottom: 10px;
}
.b3{
    padding-bottom: 15px;
}
.b4{
    padding-bottom: 20px;
}