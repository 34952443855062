.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 16px;
  line-height: 1.5;
}

/* Input */
.pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color: rgb(33, 150, 243);
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: rgb(33, 150, 243);
  background-color: rgb(33, 150, 243);
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(255, 255, 255);
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox > input:focus {
  opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color: rgb(33, 150, 243);
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}


.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  line-height: 1.5;
}

/* Input */
.pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
  display: inline-block;
  width: auto;
  cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color:black;
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color:black;
  background-color:black;
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: rgb(255, 255, 255);
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox > input:focus {
  opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color:black;
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}


//OUTLINED


// .pure-material-textfield-outlined {
//   --pure-material-safari-helper1:black;
//   position: relative;
//   display: inline-block;
//   padding-top: 6px;
//   font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
//   font-size: 14px;
//   line-height: 1.5;
//   overflow: hidden;
//   width: 100%;
// }

// /* Input, Textarea */
// .pure-material-textfield-outlined > input,
// .pure-material-textfield-outlined > textarea {
//   box-sizing: border-box;
//   margin: 0;
//   border: solid 1px; /* Safari */
//   border-color: rgba( 0, 0, 0, 0.6);
//   border-top-color: transparent;
//   border-radius: 4px;
//   padding: 15px 13px 15px;
//   width: 100%;
//   height: inherit;
//   color: rgba( 0, 0, 0, 0.87);
//   background-color: transparent;
//   box-shadow: none; /* Firefox */
//   font-family: inherit;
//   font-size: inherit;
//   line-height: inherit;
//   caret-color:black;
//   transition: border 0.2s, box-shadow 0.2s;
// }

// /* Span */
// .pure-material-textfield-outlined > input + span,
// .pure-material-textfield-outlined > textarea + span {
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: flex;
//   border-color: rgba( 0, 0, 0, 0.6);
//   width: 100%;
//   max-height: 100%;
//   color: rgba( 0, 0, 0, 0.6);
//   font-size: 75%;
//   line-height: 15px;
//   cursor: text;
//   transition: color 0.2s, font-size 0.2s, line-height 0.2s;
// }

// /* Corners */
// .pure-material-textfield-outlined > input + span::before,
// .pure-material-textfield-outlined > input + span::after,
// .pure-material-textfield-outlined > textarea + span::before,
// .pure-material-textfield-outlined > textarea + span::after {
//   content: "";
//   display: block;
//   box-sizing: border-box;
//   margin-top: 6px;
//   border-top: solid 1px;
//   border-top-color: rgba( 0, 0, 0, 0.6);
//   min-width: 10px;
//   height: 8px;
//   pointer-events: none;
//   box-shadow: inset 0 1px transparent;
//   transition: border-color 0.2s, box-shadow 0.2s;
// }

// .pure-material-textfield-outlined > input + span::before,
// .pure-material-textfield-outlined > textarea + span::before {
//   margin-right: 4px;
//   border-left: solid 1px transparent;
//   border-radius: 4px 0;
// }

// .pure-material-textfield-outlined > input + span::after,
// .pure-material-textfield-outlined > textarea + span::after {
//   flex-grow: 1;
//   margin-left: 4px;
//   border-right: solid 1px transparent;
//   border-radius: 0 4px;
// }

// /* Hover */
// .pure-material-textfield-outlined:hover > input,
// .pure-material-textfield-outlined:hover > textarea {
//   border-color: rgba( 0, 0, 0, 0.87);
//   border-top-color: transparent;
// }

// .pure-material-textfield-outlined:hover > input + span::before,
// .pure-material-textfield-outlined:hover > textarea + span::before,
// .pure-material-textfield-outlined:hover > input + span::after,
// .pure-material-textfield-outlined:hover > textarea + span::after {
//   border-top-color: rgba( 0, 0, 0, 0.87);
// }

// .pure-material-textfield-outlined:hover > input:not(:focus):placeholder-shown,
// .pure-material-textfield-outlined:hover > textarea:not(:focus):placeholder-shown {
//   border-color: rgba( 0, 0, 0, 0.87);
// }

// /* Placeholder-shown */
// .pure-material-textfield-outlined > input:not(:focus):placeholder-shown,
// .pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown {
//   border-top-color: rgba( 0, 0, 0, 0.6);
// }

// .pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span,
// .pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span {
//   font-size: inherit;
//   line-height: 68px;
// }

// .pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::before,
// .pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::before,
// .pure-material-textfield-outlined > input:not(:focus):placeholder-shown + span::after,
// .pure-material-textfield-outlined > textarea:not(:focus):placeholder-shown + span::after {
//   border-top-color: transparent;
// }

// /* Focus */
// .pure-material-textfield-outlined > input:focus,
// .pure-material-textfield-outlined > textarea:focus {
//   border-color:black;
//   border-top-color: transparent;
//   box-shadow: inset 1px 0 var(--pure-material-safari-helper1), inset -1px 0 var(--pure-material-safari-helper1), inset 0 -1px var(--pure-material-safari-helper1);
//   outline: none;
// }

// .pure-material-textfield-outlined > input:focus + span,
// .pure-material-textfield-outlined > textarea:focus + span {
//   color:black;
// }

// .pure-material-textfield-outlined > input:focus + span::before,
// .pure-material-textfield-outlined > input:focus + span::after,
// .pure-material-textfield-outlined > textarea:focus + span::before,
// .pure-material-textfield-outlined > textarea:focus + span::after {
//   border-top-color: var(--pure-material-safari-helper1) !important;
//   box-shadow: inset 0 1px var(--pure-material-safari-helper1);
// }

// /* Disabled */
// .pure-material-textfield-outlined > input:disabled,
// .pure-material-textfield-outlined > input:disabled + span,
// .pure-material-textfield-outlined > textarea:disabled,
// .pure-material-textfield-outlined > textarea:disabled + span {
//   border-color: rgba( 0, 0, 0, 0.38) !important;
//   border-top-color: transparent !important;
//   color: rgba( 0, 0, 0, 0.38);
//   pointer-events: none;
// }

// .pure-material-textfield-outlined > input:disabled + span::before,
// .pure-material-textfield-outlined > input:disabled + span::after,
// .pure-material-textfield-outlined > textarea:disabled + span::before,
// .pure-material-textfield-outlined > textarea:disabled + span::after {
//   border-top-color: rgba( 0, 0, 0, 0.38) !important;
// }

// .pure-material-textfield-outlined > input:disabled:placeholder-shown,
// .pure-material-textfield-outlined > input:disabled:placeholder-shown + span,
// .pure-material-textfield-outlined > textarea:disabled:placeholder-shown,
// .pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span {
//   border-top-color: rgba( 0, 0, 0, 0.38) !important;
// }

// .pure-material-textfield-outlined > input:disabled:placeholder-shown + span::before,
// .pure-material-textfield-outlined > input:disabled:placeholder-shown + span::after,
// .pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::before,
// .pure-material-textfield-outlined > textarea:disabled:placeholder-shown + span::after {
//   border-top-color: transparent !important;
// }

// /* Faster transition in Safari for less noticable fractional font-size issue */
// @media not all and (min-resolution:.001dpcm) {
//   @supports (-webkit-appearance:none) {
//       .pure-material-textfield-outlined > input,
//       .pure-material-textfield-outlined > input + span,
//       .pure-material-textfield-outlined > textarea,
//       .pure-material-textfield-outlined > textarea + span,
//       .pure-material-textfield-outlined > input + span::before,
//       .pure-material-textfield-outlined > input + span::after,
//       .pure-material-textfield-outlined > textarea + span::before,
//       .pure-material-textfield-outlined > textarea + span::after {
//           transition-duration: 0.1s;
//       }
//   }
// }


.material-textfield {
  position: relative;  
}

.material-textfield label{
  position: absolute;
  font-size: 14px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(66, 66, 66);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .14s ease;
  transform-origin: left top;
  pointer-events: none;
}
.material-textfield input {
  outline: none;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 5px;
  font-size: 14px;
  padding: 0.9rem 0.7rem;
  color: black;
  width: 100%;
}
.material-textfield input:focus {
  border: 2px solid black !important;
}
.material-textfield input:focus + label {
  color:black;
  top: 0;
  transform: translateY(-50%) scale(.9);
}
.material-textfield input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(.9);
}

.prietoField{
  position: relative;  
}

// .material-textfield {
//   position: relative;  
// }

// .material-textfield label {
//   position: absolute;
//   font-size: 1rem;
//   left: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   background-color: white;
//   color: gray;
//   padding: 0 0.3rem;
//   margin: 0 0.5rem;
//   transition: .1s ease-out;
//   transform-origin: left top;
//   pointer-events: none;
// }
// .material-textfield input {
//   font-size: 1rem;
//   outline: none;
//   border: 1px solid gray;
//   border-radius: 5px;  
//   padding: 1rem 0.7rem;
//   color: gray;
//   transition: 0.1s ease-out;
// }
// .material-textfield input:focus {
//   border-color: #6200EE;  
// }
// .material-textfield input:focus + label {
//   color: #6200EE;
//   top: 0;
//   transform: translateY(-50%) scale(.9);
// }
// .material-textfield input:not(:placeholder-shown) + label {
//   top: 0;
//   transform: translateY(-50%) scale(.9);
// }
header {
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 16px;
  color: rgb(255,255,255);
  background-color: rgb(33,150,243);
  text-align: center;
  font-size: 36px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

nav {
padding: 8px 0;
text-align: center;
color: rgb(33,150,243);
background-color: rgb(0,0,0);
}

nav > a {
color: rgb(33,150,243);
}

.grid {
  display: grid;
  margin: 16px auto 64px;
  max-width: 720px;
  grid-template-columns: 200px minmax(240px, 1fr) minmax(240px, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 32px;
}

.column {
font-size: 20px;
}

.title {
  padding-left: 40px;
  color: inherit;
  font-size: 18px;
}

.checkboxes > label,
.radios > label {
  display: block;
  margin: 16px 0;
}

.switches > label {
  width: 120px;
}

.switches > label:first-child {
  margin: 0 0 16px;
}

.textfields > label {
  margin: 16px 0;
}

.checkboxes > label:first-child,
.radios > label:first-child,
.textfields > label:first-child {
  margin: 0 0 16px;
}

.checkboxes > label:last-child,
.radios > label:last-child,
.textfields > label:last-child {
  margin: 16px 0 0;
}

.progresses > progress:first-child {
  margin: 0 0 16px;
}

.progresses > progress:last-child {
  margin: 16px 0 0;
}

footer {
  padding: 24px;
  text-align: center;
}

// a {
//   font-size: 16px;
//   color: rgb(33,150,243);
//   text-decoration: none;
// }

a:hover {
  text-decoration: none;
}

/* Theming */
.theming {
  position: fixed;
  left: 0;
  bottom: 0;
  border-radius: 0 8px 0 0;
  padding: 24px 24px 36px;
  color: #000;
  background-color: #eee;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: none;
  transition: transform 0.4s;
}

.theming.hidden {
  transform: translate(-70%, 70%);
}

#toggle {
  margin-bottom: 16px;
}

#toggle > span {
  font-size: 20px;
}

.theming > label {
  display: block;
  color: rgba(0, 0, 0, 0.87);
}

.theming > label > input::-webkit-slider-runnable-track {
  background-color: transparent !important;
  background: linear-gradient(to right, #000, #fff);
}

.theming > label > input::-moz-range-track {
  background-color: transparent !important;
  background: linear-gradient(to right, #000, #fff);
}

.theming > label > input::-moz-range-progress {
  background-color: transparent !important;
}

.theming > label > input::-ms-fill-lower,
.theming > label > input::-ms-fill-upper {
  background-color: transparent !important;
}

.theming > label > input::-ms-track {
  background: linear-gradient(to right, #000, #fff);
}

#primary > input::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
}

#primary > input::-moz-range-track {
  background: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
}

#primary > input::-ms-track {
  background: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00);
}

/* Overriding */
// #on-primary33,33,150,243 255,255,255;
// }

// #surface33,33,150,243 255,255,255);
// }

// #on-surface33,33,150,243 0,0,0);
// }

@media only screen and (max-width: 680px) {
  .theming {
      transform: translate(-100%, 70%);
  }
}


.clearThrough{
  border:0px;
  outline:0px;
}


.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button{
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}.red2{
  background-color: #e5575c;
}
.pay-button:disabled{
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover{
  box-shadow: none;
}
.pay-button:hover{
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}
.flexWrap{
  display:flex;
  flex-wrap: wrap;
}
.myRadio label{
  font-size: 14px !important;
  color:rgb(0, 0, 0) !important;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: black;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.material-select{
  -webkit-appearance: none;  /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  border-color: rgb(212, 212, 212);
  width:100%;
  font-size: 14px;
  padding:0.867rem 0.7rem;
  color:rgb(102, 102, 102);
  background-color: white;
  border-radius: 5px;
}
input{
  -webkit-appearance: none;  /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
}
// .material-select:hover,
// .material-select:focus,
// .material-select:active,
.material-select:focus {
  -moz-box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px 0px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px 0px;
  border-color: black !important;
  outline: none;
  border: 2px solid black !important;
  text-decoration: #000 !important;
}

.material-select option {
  border-left: solid red 2px;
  border-right: solid red 2px;
}





$md-checkbox-checked-color: rgb(51, 122, 183);
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 1em 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.125em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: .75em;

.blue2{
  color:#337ab7 !important;
}
.md-checkbox {
  position: relative;
  display: flex;
  margin: $md-checkbox-margin;
  text-align: left;

  &.md-checkbox-inline {
    display: inline-block;
  }
  
  label {
    cursor: pointer;
    display: inline;
    line-height: $md-checkbox-size;
    vertical-align: top;
    clear: both;
    font-size: 14px;
    padding-left: 6px !important;
    &:not(:empty) {
      padding-left: $md-checkbox-label-padding;
    }
    
    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }
    
    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid $md-checkbox-border-color;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background .3s;
    }
  
    &:after {
      // checkmark
    }    
  }
  
  input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
       + label:before{
        background: $md-checkbox-checked-color;
        border:none;
      }
      + label:after {
        
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;
        
        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      } 
    }
    
    &:disabled {
      + label:before{
        border-color: $md-checkbox-border-color-disabled;
      }
      &:checked {
        + label:before{
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
 
}




//SELECT DECOR

.select-text {
  border:.1px solid rgb(212, 212, 212);
	position: relative;
	font-family: inherit;
	background-color: transparent;
	padding: 17px 10px 10px 10px;
  font-size: 16px;
  color:rgb(32, 32, 32)
}

/* Remove focus */
.select-text:focus {
	outline: none;
}

	/* Use custom arrow */
.select .select-text {
	appearance: none;
	-webkit-appearance:none;
}

.select:after {
	position: absolute;
	top: 10px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.12);
  
	pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
	color: rgb(96, 96, 96);
  background: white;
	font-size: 14px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
  padding: 0 5px;
	left: 10px;
	top: 15px;
  transition: 0.2s ease all;
}


/* active state */
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
	color: black;
	top: -12px;
	transition: 0.2s ease all;
	font-size: 14px;
}

.select-text:valid:not(focus) ~ .select-label {
  	color: rgb(96, 96, 96);
}
.select-text:valid:focus ~ .select-label {
  color: black;
}


.wrap {
  position: absolute;
  right: 0;
  top: 40%;
  width: 350px;
  left: 0;
  margin: 0 auto;
}

/* select starting stylings ------------------------------*/
.select {
  font-family:
    'Roboto','Helvetica','Arial',sans-serif;
	position: relative;
	width: auto;
}

.select-text {
	position: relative;
	font-family: inherit;
	background-color: transparent;
	width: 100%;
	padding: 14px 10px 14px 14px;
	font-size: 15px;
  border-radius: 4px;
  color:rgb(96,96,96);
	border: 1px solid rgb(211, 211, 211);
}

/* Remove focus */
.select-text:focus {
	outline: none;
  border: 2px solid black;
}

	/* Use custom arrow */
.select .select-text {
	appearance: none;
	-webkit-appearance:none;
  color: rgb(34, 34, 34);
}

.select:after {
	position: absolute;
	top:22px;
	right: 10px;
	/* Styling the down arrow */
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid rgba(0, 0, 0, 0.12);
  
	pointer-events: none;
}

/* LABEL ======================================= */

.select-label {
	color: rgb(96,96,96);
  background: white;
  font-size: 15px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
  padding: 0 5px;
	left: 10px;
	top: 16px;
	transition: 0.2s ease all;
}


/* active state */
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
	color: black;
	top: -6px;
	transition: 0.2s ease all;
	font-size: 14px;
}

.select-text:valid:not(focus) ~ .select-label {
  	color: rgb(96,96,96);
}
.select-text:valid:focus ~ .select-label {
  color: black;
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
}


.stripy input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripy input::placeholder {
  color: #aab7c4;
}

.stripy input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.stripy label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripy button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.stripy button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}