/* INSTRUCCIONES PARA PANTALLAS SUPER GIANTS */

@media (min-width:1600px){
    div.mainBody{
        padding-left: 32rem !important;
        padding-right: 32rem !important;
    }
    div.mainBodyStore{
        padding-left: 14rem !important;
        padding-right: 14rem !important;
    }
}
/* INSTRUCCIONES PARA PANTALLAS SUPER GRANDES */

@media (min-width:1285px){
    .mainBody{
        padding-left: 27rem !important;
        padding-right: 27rem !important;
    }
    .mainBodyStore{
        padding-left: 12rem !important;
        padding-right: 12rem !important;
    }
}

/* INSTRUCCIONES PARA PANTALLAS GRANDES */

@media (min-width:769px){
  .shippingTotalPanel{
    display:flex;
  }
  .checkoutBody{
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 1.5fr 1fr !important;
    grid-template-rows: auto !important;
  }
    .mainBody{
        padding-left: 13rem;
        padding-right: 13rem;
    }
    .mainBodyStore{
        padding-left: 8rem !important;
        padding-right: 8rem !important;
    }
}
/* INSTRUCCIONES PARA PANTALLAS SMALL */
@media (min-width:767px) and (max-width:1000px){
  .landingBackground{
    background-size: 200% 100% !important;
    background-position: 30% 0%;
    background-repeat: no-repeat;
}
}

@media (max-width:767px){
    .mainTitle1{
        font-size: 130px !important;
        line-height: 130px !important;
    }
    .mainTitle2{
        font-size: 80px !important;
        line-height: 80px !important;
    }
    .bigShade{
        display: none !important;
    }
    .greyInactive{
      color:#545454;
    }
    .whiteColonial{
      background-color: #fafafa !important;
    }
    .landingBackground{
        background-image: url(../images/sexyMobile.webp);
        background-image: --webkit-image-set(url(../images/sexyMobile.png) 1x) !important;
        background-image: image-set(url(../images/sexyMobile.png) 1x) !important;
        
        background-size: 100% 100% !important;
        background-position: 0% 0%;
        background-repeat: no-repeat;
    }
    .mainBody{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .mainBodyStore{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
@media (max-width:767px) and (min-width:600px){
  .landingBackground{
    background-size: 100% 180% !important;
    background-position: 0% 0%;
    background-repeat: no-repeat;
}
}
.alignCenter{
    position: relative;
	display: flex;
	align-items:center;
	justify-content: center;
}
.center{
    position: relative;
    top:30%;
    left:20%;
}
.centerY{
    transform: translateY(-50%);
    position: relative;
    top:50%;
  }
  .centerX{
    transform: translateX(-50%);
    position: relative;
    left:50%;
  }
.relative{
    position: relative;
}
.grey1{
    color:#6E6E6E;
}
.blue1{
    color:#4D72D3;
}
.link{
    color:inherit;
}
a{
    color:inherit;
    text-decoration: none;
}
a:link{
    color:inherit;
    text-decoration: none;
}
a:visited{
    color:inherit;
    text-decoration: none;
}
.wide{
    width: 100%;
}
.long{
    height: 100%;
}
.textAlign{
    text-align: center;
}
.absolute{
    position: absolute !important;
}
.red1{
    color: #D8001C;
}
.green2{
    color:#37A000;
}
.half{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}
.twothirds{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
}
.logo{
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-rows: auto;
}
.tachar{
    text-decoration: line-through;
}


#menuToggle
{
  display: block;
  position: relative;
  top:2px;
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 26px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  
  background: #6E6E6E;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
  position: absolute;
  width: 300px;
  margin: -35px 0 0 -15px;
  padding: 50px;
  padding-top: 50px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  border: 2px solid #000000;
box-sizing: border-box;
border-top-right-radius: 20px;
border-bottom-right-radius: 20px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}




.modalBg{
    z-index: 9999;
    position:fixed;
    top: 0px;
    left:0px;
    width: 100vw;
    height:100vh;
background: rgba(0, 0, 0, 0.7);

}
.popUpCard{
    /* Rectangle 37 */

position: relative;
width: 354px;
height: 232px;

background: #FFFFFF;
border-radius: 20px;

}
/*    QLOQQQ    */

*::selection {
    color: white;
    background: tomato;
  }
  
  .loginButton {
    outline: none !important;
    border-radius: 0 !important;
    border: 0 !important;
    background: #CC2C21;
    color: white;
    transition: .25s background ease-in-out;
  }
  
  .loginButton:hover,
  .loginButton:focus,
  .loginButton:active {
    background: #FFF;
  }
  
  .copyright {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #777;
    margin-top: 100px;
  }
  
  .form-control {
    transition: .25s border ease-in-out;
    border-radius: 0 !important;
    outline: none !important;
    box-shadow: none !important;
    background: transparent;
    color: white;
    border: 0;
    border-bottom: 2px solid #444;
    font-size: 20px;
  }
  
  .form-control:focus {
    border-bottom-color: #CC2C21;
  }
  
  .errorMessages {
    margin-top: 50px;
    padding: 25px;
    color: #A94442;
    background: #202020;
    border: 0 !important;
    text-shadow: none !important;
    box-shadow: 0 0 10px #151515;
    text-align: center;
  }
  
  .form-control:not(.touched)+.floater:before {
    display: block;
    position: absolute;
    top: 0;
    font-size: 20px;
    color: #666;
    pointer-events: none;
    transition: .25s all ease-in-out;
  }
  
  .form-control.touched+.floater:before,
  #username:focus+.floater:before,
  #username:not(:placeholder-shown)+.floater:before,
  #password:focus+.floater:before,
  #password:not(:placeholder-shown)+.floater:before {
    position: absolute;
    top: -20px;
    font-size: 14px;
    color: #FFF;
  }

  #username+.floater:before,
  #username:focus+.floater:before {
    content: "Username";
  }
  
  #password+.floater:before,
  #password:focus+.floater:before {
    content: "Password";
  }
  
  .link,
  .link:hover {
    color: #CC2C21;
    text-decoration: none;
  }




  /* BootStrap */

.hidden-xs-up{display:none !important}@media(max-width:575.98px){.hidden-xs-down{display:none !important}}@media(min-width:576px){.hidden-sm-up{display:none !important}}@media(max-width:767.98px){.hidden-sm-down{display:none !important}}@media(min-width:768px){.hidden-md-up{display:none !important}}@media(max-width:991.98px){.hidden-md-down{display:none !important}}@media(min-width:992px){.hidden-lg-up{display:none !important}}@media(max-width:1199.98px){.hidden-lg-down{display:none !important}}@media(min-width:1200px){.hidden-xl-up{display:none !important}}.hidden-xl-down{display:none !important}.visible-print-block{display:none !important}@media print{.visible-print-block{display:block !important}}.visible-print-inline{display:none !important}@media print{.visible-print-inline{display:inline !important}}.visible-print-inline-block{display:none !important}@media print{.visible-print-inline-block{display:inline-block !important}}@media print{.hidden-print{display:none !important}}

.invisibless{
    outline: none !important;
    border:0px;
}
.material {
	position: relative;
	padding: 0;
	margin: 5px;
	border: none;
	overflow: visible;
}
.fieldBox input{
    height: auto;
}
	.material input {
		box-sizing: border-box;
		width: 100%;
		padding: 20px 10px 8px;
		border: none;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid #DDD;
		font-size: 15px;
		outline: none;
		cursor: text;

		&::-webkit-input-placeholder {
			transition: color 300ms ease;
		}
		&:not(:focus)::-webkit-input-placeholder {
			color: transparent;
		}
	}
	
	.material hr {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 2px;
		border: none;
		background: orange;
		font-size: 1px;
		will-change: transform, visibility;
		transition:all 200ms ease-out;
		transform: scaleX(0);
		visibility: hidden;
		z-index: 10;
	}
	.material input:focus ~ hr {
		transform: scaleX(1);
		visibility: visible;
	}
	
	.material label {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 17px;
		color: rgba(0, 0, 0, 0.5);
		transform-origin: 0 -150%;
		transition: transform 300ms ease;
		pointer-events: none;
  }
  #orderRow * {
    transition: all 0.5s ease;
  }
  #orderRow{
    transition: height 0.5s ease;
  }
  .rotate * {
    transform: rotate(-90deg);
    transform-origin: center center;
    /* position:relative;
    top:-10px; */
  }
	.material input:focus ~ label,
	.material input:valid ~ label {
		transform: scale(0.7);
	}



    
    .lds-roller {
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 80px;
      }
      .lds-roller div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
      }
      .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: black;
        margin: -4px 0 0 -4px;
      }
      .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
      }
      .lds-roller div:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
      }
      .lds-roller div:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
      }
      .lds-roller div:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
      }
      .lds-roller div:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
      }
      .lds-roller div:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
      }
      .lds-roller div:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
      }
      .lds-roller div:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
      }
      .lds-roller div:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
      @keyframes lds-roller {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      
.grey2{
  color:#737373;
}